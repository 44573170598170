.chatScreen {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 15px 20px rgb(0 0 0 / 10%);
  transition: bottom 0.5s linear;
}
.chatHeader {
  padding: 5px 13px 15px 13px;
  display: flex;
  line-height: initial;
  height: 80px !important;
}
.headerIcon {
  display: inline-flex;
  width: 50px;
  align-items: center;
}
.img {
  border-radius: 50%;
  z-index: 2147483647 !important;
  border: 3px solid rgba(255, 255, 255, 0.5);
  width: 60px;
  margin-bottom: -32px;
  height: 60px;
  position: relative;
}
.chatScreen .chatHeader .titleHeader {
  bottom: -10px;
  position: relative;
  width: calc(100% - 70px);
  margin-left: 20px;
  margin-top: 2px;
  font-size: 14px;
  overflow: hidden;
}
.chatScreen .chatHeader .titleHeader .title {
  width: 100%;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
}
.caption {
  width: 100%;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chatContainer {
  background: rgb(245, 244, 244);
}
.keypad {
  display: inline-flex;
  max-height: 50px;
  min-height: 50px;
  border-bottom: 1px solid #f1f1f1;
  background: #fff;
  box-shadow: rgb(0 0 0 / 3%) 0px -1em 1em;
}
.textInput {
  width: 100%;
  position: relative;
  margin-bottom: -5px;
}
.input {
  width: calc(100% - 34px);
  background: #ffffff;
  padding: 15px 15px 15px 15px;
  border-radius: 0 0 15px 15px;
  resize: none;
  box-shadow: rgb(0 0 0 / 3%) 0px -1em 1em;
  border: none;
  color: #7a7a7a;
  font-weight: normal;
  font-size: 13px;
  transition: border-color 0.5s ease;
  outline: 0;
}
.sendIcon {
  width: 40px;
  white-space: nowrap;
  position: absolute;
  top: 10px;
  display: flex;
  align-items: center;
  right: 27px;
  text-align: right;
}
.anchor {
  margin: 0px 3px 0px 3px !important;
  height: 30px;
  align-items: center;
  display: flex;
  width: 30px;
  text-align: center;
  color: #777 !important;
}
.cbotSingleCard {
  padding-left: 0;
  padding-right: 0;
}
.chatScreen .footer {
  font-size: 12px;
  background: #fff;
  color: #fff;
  min-height: 30px;
  max-height: 30px;
  padding: 7px 4px 0px 4px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-align: center;
}
.poweredBy {
  margin-top: 5px;
  display: inline-flex;
  align-items: center;
  margin: 0px 10px 10px auto;
  color: #ababab !important;
  text-decoration: none;
  float: right;
  padding: 5px 3px -1px 3px;
}
.homeButton {
  position: relative;
  bottom: 4px;
  width: 20px;
  font-size: 20px;
  margin: 3px;
}
.homeButton div {
  width: 20px;
  margin: 3px;
}
.restart {
  position: relative;
  font-size: 20px !important;
  margin-bottom: 3px;
}
.cbotTime {
  float: left;
  text-align: left;
  margin-left: 10px;
  margin-top: -3px;
  font-size: 11px;
  color: #b4b4b4;
  text-transform: capitalize;
}
.attachments {
  display: flex;
  flex-direction: row;
}
.menu {
  width: 20px;
  font-size: 20px;
}
.attachment {
  position: absolute;
  right: 38px;
  top: 18px;
}
.poweredByLink {
  font-size: 13px;
  color: #ababab !important;
  display: inline-block;
  text-shadow: 0 2px 0 rgb(255 255 255);
  text-decoration: none;
  cursor: pointer;
}
.footerLogo {
  height: 20px;
  margin-top: 1px;
}
.emoji-box {
  height: 50px !important;
  margin-left: 3px;
  border-right: 1px solid #eeeeee;
  background: #fff;
}
.emoji {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  color: #777 !important;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-decoration: none;
  cursor: pointer;
}
.emoji:hover {
  transform: scale(1.1);
}
.chats {
  padding: 0.5em;
  overflow: hidden;
  min-height: calc(100% - 143px);
}
.userResponse {
  animation: animateElement linear 0.2s;
  animation-iteration-count: 1;
  margin: 0;
  background: rgb(0, 57, 101);
  color: rgb(255 255 255);
  word-wrap: break-word;
  padding: 10px 15px 10px 15px;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
  float: right;
  border-radius: 15px 0px 15px 15px;
  line-height: 20px;
  font-size: 13px;
  max-width: 70%;
  min-width: 25%;
  line-height: 20px;
  margin-bottom: 0;
  font-weight: 400;
}
.closeButtonAnchor {
  margin-right: 8px;
  margin-top: 20px;
  color: rgb(255, 255, 255);
}
.cBot-menu div {
  align-items: center;
  display: block;
  width: 40px;
  height: 3px;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 5px;
  background-color: rgba(66, 66, 66, 0.1);
}
.botdata {
  border-radius: 10px 10px 0 0;
  width: 100%;
  position: absolute;
  background: #fff;
}
.menuHeader {
  text-align: center;
  padding: 5px 5px;
  font-size: 15px;
  background: #fff;
  border-radius: 31px 31px 0 0;
}
.attachIcons {
  display: block;
  position: absolute;
  margin: 0;
  background: #fff;
  list-style-type: none;
  color: #a0a0a0;
  left: 0;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: rgb(0 0 0 / 3%) 0px -1em 1em;
  z-index: 2147483647 !important;
  width: -webkit-fill-available;
  width: -moz-available;
  cursor: auto;
  max-height: 60px;
  margin-top: -3px;
}
.botList {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  margin: 1px;
  margin-left: auto;
  padding-top: 3px;
  padding-inline-start: 0 !important;
}
.nav-item {
  width: 25%;
}
.icons {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  height: 34px;
  overflow-y: hidden;
  margin: 1px;
  margin-left: auto;
  padding-right: 8px;
  padding-top: 3px;
  padding-inline-start: 0 !important;
  float: right;
}
.listGroup {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.botList .nav-item:hover {
  filter: opacity(50%);
}
.listMenu {
  padding: 5px 10px 5px 15px;
  color: #212529;
  font-weight: 500;
  background: #fff;
  border-radius: 0px;
  font-size: 13px !important;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.bi {
  cursor: pointer;
}
.cBot-crop {
  height: 40px;
  width: 40px;
  margin-right: 5px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f7f7f7;
  text-decoration: none;
  cursor: pointer;
}
.cBot-attach {
  display: inline-block;
  cursor: pointer;
  padding-left: 10px;
}

.img_microphone {
  height: 30px;
  width: 30px;
  margin-top: 10px;
  float: right;
}
.cBot-attach #text {
  cursor: pointer !important;
}

.cBot-attach input {
  display: none;
}
.buttonContainer {
  box-sizing: unset !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px;
  width: 60px !important;
  background-position: 0% 0% !important;
  background-size: contain !important;
  height: 60px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  position: absolute !important;
}
.chatBotClearFix {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 100%;
}
.chatBotClearFix:after {
  display: block;
  clear: both;
  content: "";
}
.popUpBubble {
  display: block;
  flex-direction: column;
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  box-sizing: unset !important;
  line-height: 1.1 !important;
  max-height: 60vh !important;
  position: absolute !important;
  border-radius: 8px !important;
  width: 70%;
  max-width: 250px !important;
  cursor: pointer !important;
}
.cBotRightTime {
  float: right;
  text-align: right;
  margin-top: -3px;
  font-size: 11px;
  color: #b4b4b4;
}
.closeButtonPopup {
  background-color: rgb(255, 255, 255);
  box-sizing: unset !important;
  line-height: 18px !important;
  right: -20px !important;
  top: -10px !important;
  position: absolute !important;
  text-align: center !important;
  width: 20px !important;
  height: 20px !important;
  padding: 5px 3px 0px !important;
  border-radius: 50% !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 10px 1px,
    rgb(0 0 0 / 12%) 0px 0px 20px 0px !important;
  cursor: pointer !important;
}
.bubbleText {
  box-sizing: unset !important;
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 10px 1px,
    rgb(0 0 0 / 12%) 0px 0px 20px 0px !important;
  padding: 12px 10px 12px 10px !important;
  font-size: 15px !important;
  border-radius: 8px !important;
  width: 100% !important;
  cursor: pointer !important;
  display: inline-flex !important;
  overflow-wrap: normal;
}
.botMessage {
  padding: 10px 15px 10px 15px;
  float: left;
  margin-top: 0;
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px;
  margin-bottom: 0;
  background: rgb(255, 254, 254);
  color: rgb(21, 32, 49);
  margin-left: 0.5em;
  border-radius: 0 15px 15px 15px;
  max-width: 70%;
  min-width: 10%;
  font-size: 13px !important;
  word-wrap: break-word;
  font-weight: 400 !important;
  line-height: 20px;
}
.chatBubble {
  box-shadow: rgb(0 0 0 / 16%) 0px 0px 10px 1px,
    rgb(0 0 0 / 12%) 0px 0px 20px 0px;
  box-sizing: unset !important;
  background: rgb(255, 255, 255) !important;
  margin-top: 15px !important;
  border: 0px !important;
  padding: 5px 10px !important;
  color: rgb(0, 0, 0) !important;
  font-size: 13px !important;
  font-family: Helvetica, Arial, sans-serif !important;
  border-radius: 8px !important;
  height: 30px !important;
  width: 100% !important;
  cursor: pointer !important;
  display: inline-flex !important;
}
.chatInput {
  box-sizing: unset !important;
  background: rgb(255, 255, 255) !important;
  border: 0px !important;
  color: rgb(0, 0, 0) !important;
  font-size: 13px !important;
  font-family: Helvetica, Arial, sans-serif !important;
  outline: none !important;
  height: 100% !important;
  width: calc(100% - 30px) !important;
  cursor: pointer !important;
}
.chatBubbleSend {
  margin-top: 2px;
  box-sizing: unset !important;
  line-height: normal !important;
  float: right !important;
  padding: 5px 3px 3px 4px !important;
  width: 20px !important;
  cursor: pointer !important;
  border-radius: 50% !important;
  box-shadow: rgb(0 0 0 / 37%) 0px 1px 4px 0px !important;
  align-items: center !important;
}
@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.pickerStyle {
  background: #fff;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 10px #efefef;
  overflow: hidden;
  position: absolute;
}
.smartphone {
  width: 25%;
  max-width: 335px;
  height: 69vh;
  max-height: 645px;
  margin: auto;
  border: 9px black solid;
  border-top-width: 40px;
  border-bottom-width: 40px;
  border-radius: 36px;
  position: fixed;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -19px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}
/* top line needs a little padding */
.bot-custom-menu span:nth-child(1) {
  margin-top: 0.3em;
}

/**
* Animate collapse into X.
*/

/* top line rotates 45 degrees clockwise and moves up and in a bit to close the center of the X in the center of the button */
.bot-custom-menu:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}
/* center line goes transparent */
.bot-custom-menu:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}
/* bottom line rotates 45 degrees counter clockwise, in, and down a bit to close the center of the X in the center of the button  */
.bot-custom-menu:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}

/**
* Animate collapse open into hamburger menu
*/

/* top line moves back to initial position and rotates back to 0 degrees */
.bot-custom-menu span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}
/* middle line goes back to regular color and opacity */
.bot-custom-menu span:nth-child(2) {
  opacity: 1;
}
/* bottom line goes back to initial position and rotates back to 0 degrees */
.bot-custom-menu span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}
